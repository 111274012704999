import React, { useEffect, useRef } from 'react';
import './css/index.scss';
import { useAtomValue, useSetAtom, useAtom } from 'jotai';
import Sidebar from '../SideBar';
import { containerRefCurrentAtom, isSmallWindowAtom } from '../../atoms/globalAtoms';
import MyModal from '../MyModal';

function Wrapper({ children }) {
  const containerRefCurrent = useRef(null);
  const setContainerRefCurrent = useSetAtom(containerRefCurrentAtom);
  const isSmallWindow = useAtomValue(isSmallWindowAtom);

  useEffect(() => {
    if (!containerRefCurrent.current) return;

    setContainerRefCurrent(containerRefCurrent.current);
  }, [containerRefCurrent.current]);

  return (
    <div className="wrapper">
      <Sidebar />
      <div id="container" className={`container ${isSmallWindow && 'small'}`} ref={containerRefCurrent}>
        <div className="container_inner">
          {children}
        </div>
      </div>

      <MyModal />
    </div>
  );
}

export default Wrapper;
