import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import useComponentVisible from '../../../hooks/UseComponentVisible';
import InfoIcon from '../../../assets/icons/info.svg';
import styles from '../css/index.module.scss';

function InfoComponent({ component }) {
  const [clickPosition, setClickPosition] = useState([0, 0]);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible();

  const onClick = (ev) => {
    setIsComponentVisible((prev) => !prev);
    setClickPosition([ev.pageX, ev.pageY]);
  };

  const handleScroll = () => {
    setIsComponentVisible(false);
  };

  useEffect(() => {
    if (isComponentVisible) {
      document.getElementById('container').addEventListener('scroll', handleScroll);
    }

    return () => {
      document.getElementById('container')?.removeEventListener('scroll', handleScroll);
    };
  }, [isComponentVisible]);

  return (
    <div>
      <div
        className={styles.infoComponentButton}
        onClick={onClick}
      >
        <img src={InfoIcon} alt="i" />
        <FloatingComponent
          isOpen={isComponentVisible}
          refVal={ref}
          component={component}
          position={clickPosition}
        />
      </div>

    </div>
  );
}

function FloatingComponent({
  isOpen, refVal, component, position,
}) {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      ref={refVal}
      className={classNames(styles.infoComponent, { [styles.infoComponentVisible]: isOpen })}
      style={{ left: `${position[0]}px`, top: `${position[1]}px` }}
    >
      {component}
    </div>,
    document.body,
  );
}

export default InfoComponent;
