import React, { useMemo } from 'react';

function SimpleObject({ object, isSelected }) {
  const style = {
    backgroundColor: object.type === 'wall' ? '#CBD1DA' : '#EEF4FB',
    border: object.type === 'wall' ? '0' : '4px solid #A1C7FA',
    borderRadius: object.fields.borderRadius,
    width: object.fields.width,
    height: object.type === 'chair' ? object.fields.width : object.fields.height,
    flex: '0 0 auto',
    outline: isSelected ? '3px solid #dad300' : 'none',
    outlineOffset: '2px',
    transform: `rotateZ(${object.fields.rotation}deg)`,
  };

  return (
    <div style={style} />
  );
}

export default SimpleObject;
