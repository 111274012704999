import React, { useEffect, useRef } from 'react';
import { useAtom } from 'jotai/index';
import styles from '../css/index.module.scss';
import PageTitle from '../../../components/PageTitle';
import MyButton from '../../../components/MyButton';
import MyInput from '../../../components/MyInput';
import IconButton from '../../../components/IconButton';
import { selectedAddressAtom } from '../../../atoms/accountAtoms';
import {
  closeModalAtom, modalStateAtom, openInfoModalAtom, openLoadingModalAtom,
} from '../../../atoms/globalAtoms';
import { contactsFiltersAtom } from '../../../atoms/contactsAtoms';
import InfoIcon from '../../../assets/icons/info.svg';
import ContactsServices from '../../../services/ContactsServices';
import ContactsImportInfoModal from './ContactsImportInfoModal';

function ContactsHeader({ saveImportedContacts }) {
  const fileInput = useRef(null);
  const [contactsFilters, setContactsFilters] = useAtom(contactsFiltersAtom);
  const [selectedAddress] = useAtom(selectedAddressAtom);
  const [, openLoadingModal] = useAtom(openLoadingModalAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);

  const onExportClick = async () => {
    if (!selectedAddress) return;
    openLoadingModal();
    await ContactsServices.generateExcel(contactsFilters, selectedAddress.name);
    closeModal();
  };

  const onImportClick = async () => {
    if (!fileInput.current) return;
    fileInput.current.click();
  };

  const onImportFileSelect = async (ev) => {
    if (!ev?.target?.files?.length) return;
    const data = await ContactsServices.convertImportedCSV(ev.target.files[0]);
    const { error, data: convertedContacts } = ContactsServices.checkImportedData(data);

    if (!error && Array.isArray(convertedContacts) && convertedContacts.length) {
      saveImportedContacts(convertedContacts);
    } else {
      openInfoModal({
        title: 'Невалидный файл',
        text: 'Выберите файл формата .csv с необходимыми полями',
      });
    }
  };

  const onInfoClick = () => {
    setModalState({
      isOpen: true,
      children: <ContactsImportInfoModal closeModal={closeModal} />,
    });
  };

  useEffect(() => {
    if (!selectedAddress?.id) return;

    setContactsFilters({ address: selectedAddress.id });
  }, [selectedAddress]);

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.headerLeftTop}>
          <PageTitle text="Контакты" />
        </div>
        <div className={styles.headerLeftBottom}>
          <MyInput
            value={contactsFilters.searchText}
            onChange={(ev) => setContactsFilters({ searchText: ev.target.value || undefined })}
            placeholder="Поиск"
            functionAtCleaning={() => setContactsFilters({
              searchText: undefined,
              newDate: contactsFilters.searchText?.length ? '' : contactsFilters.date,
            })}
          />
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.headerRightTop}>
          <MyButton
            text="Экспорт"
            onClick={onExportClick}
          />
        </div>
        <div className={styles.headerRightBottom}>
          <IconButton
            svgPath={InfoIcon}
            onClick={onInfoClick}
            wrapperClassName={styles.iconButton}
          />
          <MyButton
            text="Импорт"
            onClick={onImportClick}
            secondary
          />
          <input
            ref={fileInput}
            type="file"
            accept=".csv, text/csv"
            onChange={onImportFileSelect}
            className={styles.hiddenInput}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactsHeader;
