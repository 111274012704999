import React from 'react';
import SchemeDots from '../SchemeDots';
import SchemeLines from '../SchemeLines';
import SchemeSvg from '../SchemeSvg';
import SchemeLineDots from '../SchemeLineDots';
import SchemeMiddleDot from '../SchemeMiddleDot';

function PlanScheme() {
  return (
    <>
      <SchemeMiddleDot />
      <SchemeLines />
      <SchemeLineDots />
      <SchemeDots />
      <SchemeSvg />
    </>
  );
}

export default PlanScheme;
