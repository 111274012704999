import React from 'react';
import ContactsImport from '../../../assets/images/contactsImport.png';
import CloseIcon from '../../../assets/formIcons/close.svg';
import styles from '../css/index.module.scss';
import IconButton from '../../../components/IconButton';

function ContactsImportInfoModal({ closeModal }) {
  return (
    <div className="modal_wrapper" style={{ height: `${window.innerHeight - 200}px` }}>
      <div className={styles.infoModalHeader}>
        <div style={{ width: '48px' }} />
        <p className="modal_title_no_margin">Информация по импорту контактов</p>
        <IconButton svgPath={CloseIcon} onClick={closeModal} />
      </div>
      <div className={styles.infoModalContainer}>
        <img src={ContactsImport} alt="" />
      </div>
    </div>
  );
}

export default ContactsImportInfoModal;
