import React from 'react';
import { useAtom } from 'jotai/index';
import MyButton from '../../../components/MyButton';
import { cancelingReservesLoadingAtom } from '../../../atoms/reservesAtoms';

function CancelReserveModal({ onConfirm, onClose }) {
  const [cancelingReserveLoading] = useAtom(cancelingReservesLoadingAtom);

  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">Отменить резерв?</p>
        <p className="modal_descr">
          Вы уверены что хотите отменить этот резерв? Отменить данное действие будет
          невозможно.
        </p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text="Закрыть" secondary onClick={onClose} disabled={cancelingReserveLoading} />
        <MyButton text="Подтвердить" onClick={onConfirm} loading={cancelingReserveLoading} />
      </div>
    </div>
  );
}

export default CancelReserveModal;
