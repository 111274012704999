import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import lodash from 'lodash';

class ExcelServices {
  static generateData({
    headers,
    data,
    fn,
  }) {
    const finalData = [];
    data.forEach((item) => {
      const obj = {};
      Object.keys(headers).forEach((key) => {
        const value = (fn(key, item) || lodash.get(item, headers[key].path))
            ?? '-';
        obj[headers[key].headerText] = String(value);
      });
      finalData.push(obj);
    });
    return finalData;
  }

  static generateColumnWidth(finalData) {
    const headLengthsList = {};
    finalData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (headLengthsList[key]) {
          headLengthsList[key].push(item[key].toString().length);
        } else {
          headLengthsList[key] = [key.length, item[key].toString().length];
        }
      });
    });

    return Object.keys(headLengthsList).map((key) => {
      const max = Math.max(...headLengthsList[key]);
      return { wch: max + 3 };
    });
  }

  static generateFile({
    headers,
    data,
    fn,
    fileName = 'reservista-table',
  }) {
    const finalData = this.generateData({ headers, data, fn });
    const worksheet = XLSX.utils.json_to_sheet(finalData);
    worksheet['!cols'] = this.generateColumnWidth(finalData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Страница 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  }
}

export default ExcelServices;
