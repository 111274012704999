import React from 'react';
import styles from './css/index.module.scss';

function LoadingModal() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader} />
    </div>
  );
}

export default LoadingModal;
