import React, { useEffect, useRef } from 'react';
import { useAtomValue, useSetAtom, useAtom } from 'jotai';
import Draggable from 'react-draggable';
import styles from './css/index.module.scss';
import useResize from '../../../../hooks/UseResize';
import PlanServices from '../../../../services/PlanServices';
import {
  gridDraggedPositionAtom,
  gridRefCurrentAtom,
  gridZoomScaleAtom, selectedObjectIndexAtom,
} from '../../../../atoms/schemeAtoms';
import PlanBackgroundButtons from '../PlanBackgroundButtons';

function PlanBackground({ children }) {
  const setGridRefCurrent = useSetAtom(gridRefCurrentAtom);
  const setSelectedObjectIndex = useSetAtom(selectedObjectIndexAtom);
  const [gridZoomScale, setGridZoomScale] = useAtom(gridZoomScaleAtom);
  const [gridDraggedPosition, setGridDraggedPosition] = useAtom(gridDraggedPositionAtom);

  const wrapperRef = useRef();
  const gridRef = useRef();
  const { width, height } = useResize(wrapperRef);

  const onGridDrag = (_, data) => {
    setGridDraggedPosition([data.x, data.y]);
  };

  const onBackgroundClick = () => {
    setSelectedObjectIndex(null);
  };

  useEffect(() => {
    if (gridRef.current) {
      setGridRefCurrent(gridRef.current);
    }
  }, [gridRef]);

  return (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
      onClick={onBackgroundClick}
    >
      <Draggable onDrag={onGridDrag} position={{ x: gridDraggedPosition[0], y: gridDraggedPosition[1] }}>
        <div>
          <div
            ref={gridRef}
            id="grid"
            className={styles.grid}
            style={{
              width: `${PlanServices.gridSize}px`,
              height: `${PlanServices.gridSize}px`,
              marginLeft: `-${(PlanServices.gridSize - width) / 2}px`,
              marginTop: `-${(PlanServices.gridSize - height) / 2}px`,
              transform: `scale(${gridZoomScale})`,
            }}
          >
            {children}
          </div>
        </div>
      </Draggable>

      <PlanBackgroundButtons />
    </div>
  );
}

export default PlanBackground;
