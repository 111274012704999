import { TAGS_COLOR_MODES } from '../components/ColorfulTag';

export const RESERVE_STATUSES = {
  waiting: {
    key: 'waiting',
    name: 'Ожидание',
    colorMode: TAGS_COLOR_MODES.yellow,
  },
  delay: {
    key: 'delay',
    name: 'Опаздывает',
    colorMode: TAGS_COLOR_MODES.yellow,
  },
  came: {
    key: 'came',
    name: 'Пришёл',
    colorMode: TAGS_COLOR_MODES.green,
  },
  finished: {
    key: 'finished',
    name: 'Завершен',
    colorMode: TAGS_COLOR_MODES.violet,
  },
  canceled: {
    key: 'canceled',
    name: 'Отменён хостесом',
    colorMode: TAGS_COLOR_MODES.red,
  },
  missed: {
    key: 'missed',
    name: 'Не пришёл',
    colorMode: TAGS_COLOR_MODES.red,
  },
};

export const PARTNERS_PAGE_STATES = {
  partnersList: 'partnersList',
  singlePartnerInfo: 'singlePartnerInfo',
  singlePartnerAddresses: 'singlePartnerAddresses',
  singlePartnerSingleAddress: 'singlePartnerSingleAddress',
};
