import React, { useEffect } from 'react';
import { useAtom, useSetAtom } from 'jotai/index';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import { closeModalAtom, modalStateAtom, openInfoModalAtom } from '../atoms/globalAtoms';
import LoadingModal from '../components/LoadingModal';
import { contactsFiltersAtom, contactsInfoAtom, gettingContactsLoadingAtom } from '../atoms/contactsAtoms';
import ContactsServices from '../services/ContactsServices';
import sleep from '../helpers/sleep';

const UseContacts = () => {
  const [contactsFilters, setContactsFilters] = useAtom(contactsFiltersAtom);
  const [contactsInfo, setContactsInfo] = useAtom(contactsInfoAtom);
  const [gettingContactsLoading, setGettingContactsLoading] = useAtom(gettingContactsLoadingAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const [, openInfoModal] = useAtom(openInfoModalAtom);

  const getContacts = async () => {
    const payload = ContactsServices.convertTableFilters(contactsFilters);
    const data = await sendRequest({
      request: Api.getContacts,
      payload,
      warnErrorText: 'while getting contacts',
      setLoading: setGettingContactsLoading,
    });

    if (data?.status !== 'ok') return;
    setContactsInfo(data.data);
  };

  const saveImportedContacts = async (contacts) => {
    if (!contactsFilters?.address || !contacts?.length) return;

    const data = await sendRequest({
      request: Api.saveImportedContacts,
      payload: { address_id: contactsFilters.address, contacts },
      warnErrorText: 'while saving imported contacts',
      setLoading: setGettingContactsLoading,
    });

    await sleep(300);

    if (data?.status !== 'ok') {
      return openInfoModal({
        title: 'Ошибка',
        text: data.message,
      });
    }
    openInfoModal({
      title: 'Успешно!',
      text: `Новые контакты (${contacts.length}) были успешно сохранены`,
      onButtonClick: getContacts,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getContacts().then();
    }, 500);

    return () => clearTimeout(timer);
  }, [contactsFilters?.date]);

  useEffect(() => {
    if (!gettingContactsLoading) return closeModal();

    setModalState({
      isOpen: true,
      closeOnPressOutside: false,
      children: <LoadingModal />,
    });
  }, [gettingContactsLoading]);

  return { saveImportedContacts };
};

export default UseContacts;
