import React, { useCallback, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import styles from './css/index.module.scss';
import PlanServices from '../../../../services/PlanServices';
import { schemeDotsAtom } from '../../../../atoms/schemeAtoms';
import { COLORS } from '../../../../helpers/constants';

function SchemeSvg() {
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);

  const pointsToString = useMemo(() => schemeDots.map((point) => `${point[0]},${point[1]}`).join(' '), [schemeDots]);

  return (
    <svg width={PlanServices.gridSize} height={PlanServices.gridSize}>
      <polygon
        points={pointsToString}
        style={{ fill: 'white', stroke: COLORS.textSecondary, strokeWidth: 2 }}
      />
    </svg>
  );
}

export default SchemeSvg;
