import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAtom } from 'jotai/index';
import ProtectedRoutes from './navigation/ProtectedRoutes';
import UnprotectedRoutes from './navigation/UnprotectedRoutes';
import LoginPage from './pages/Login';
import { accountLoginAtom, isRootAtom, tokenAtom } from './atoms/globalAtoms';
import LocalStorageServices from './services/LocalStorageServices';
import sendRequest from './helpers/sendRequest';
import Api from './api';
import { addressesAtom, modulesAtom, selectedAddressAtom } from './atoms/accountAtoms';
import sleep from './helpers/sleep';

function App() {
  const [isReady, setIsReady] = useState(false);

  const [token, setToken] = useAtom(tokenAtom);
  const [, setAddresses] = useAtom(addressesAtom);
  const [, setSelectedAddress] = useAtom(selectedAddressAtom);
  const [modules, setModules] = useAtom(modulesAtom);
  const [, setAccountLogin] = useAtom(accountLoginAtom);
  const [, setIsRoot] = useAtom(isRootAtom);

  const isAuthorized = Boolean(token);
  const authorizedFirstScreen = modules[0]?.path;
  const unAuthorizedFirstScreen = '/login';

  useEffect(() => {
    const tokenFromStorage = LocalStorageServices.getToken();
    if (tokenFromStorage) {
      setToken(tokenFromStorage);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (token) {
        const data = await sendRequest({
          request: Api.init,
          warnErrorText: 'while init',
        });

        if (data?.status !== 'ok') {
          await sleep(400);
          setIsReady(true);
          return;
        }

        setAddresses(data.data.addresses);
        setSelectedAddress(data.data.addresses[0] || null);
        if (data.data.root) {
          // for opening the tab from addresses
          data.data.modules.push({
            name: 'План помещений',
            sort: 3,
            icon: 'plan',
            module_name: 'plan',
            content: null,
            show: false,
          });
        }
        setModules(data.data.modules);
        setAccountLogin(data.data.login);
        setIsRoot(data.data.root);
      }

      await sleep(400);
      setIsReady(true);
    })();
  }, [token]);

  if (!isReady) return null;

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} hasAccess={isAuthorized} />}
        >
          <Route index element={<LoginPage />} />
        </Route>

        <Route path="/" element={<ProtectedRoutes hasAccess={isAuthorized} unAuthorizedFirstScreen={unAuthorizedFirstScreen} />}>
          {Object.values(modules).map((item) => (
            <Route key={item.path} path={item.path} element={item.component} />
          ))}
          <Route path="/" element={<Navigate to={authorizedFirstScreen} replace />} />
        </Route>

        <Route path="/" element={<Navigate to={isAuthorized ? authorizedFirstScreen : unAuthorizedFirstScreen} replace />} />
        <Route path="*" element={<Navigate to={isAuthorized ? authorizedFirstScreen : unAuthorizedFirstScreen} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
