import React from 'react';
import styles from './css/index.module.scss';
import MyButton from '../../../../components/MyButton';

function InfoModal({ text, onClose }) {
  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">Успешно</p>
        <p className="modal_descr">
          {text}
        </p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text="ОК" onClick={onClose} />
      </div>
    </div>
  );
}

export default InfoModal;
