import { atom } from 'jotai';
import DateServices from '../services/DateServices';

const reservesTableFiltersAtom = atom({
  startDate: DateServices.getThisMonthFirstDay(),
  endDate: DateServices.getThisMonthLastDay(),

  // for properly updating
  date: new Date().toISOString(),
}, (get, set, update) => {
  set(reservesTableFiltersAtom, {
    ...get(reservesTableFiltersAtom),
    page: 1,
    ...update,

    // for properly updating
    newDate: null,
    date: update.newDate || new Date().toISOString(),
  });
});

const reservesModeAtom = atom('table'); // table | charts

const reservesDropdownSetterAtom = atom();

const reservesInfoAtom = atom({});
const gettingReservesLoadingAtom = atom(false);
const cancelingReservesLoadingAtom = atom(false);

export {
  reservesTableFiltersAtom,
  reservesModeAtom,
  reservesDropdownSetterAtom,
  reservesInfoAtom,
  gettingReservesLoadingAtom,
  cancelingReservesLoadingAtom,
};
