import moment from 'moment';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import ExcelServices from './ExcelServices';
import { DATE_FORMAT } from '../constants';
import DateServices from './DateServices';
import { RESERVES_EXCEL_HEADERS } from '../constants/excelHeaders';
import convertPhoneNumber from '../helpers/convertPhoneNumber';

class ReservesServices {
  static RESERVES_PER_PAGE = 50;

  static filterExcelHeaders = (headers, isRoot) => {
    const availableKeys = Object.keys(headers).filter((k) => isRoot || !headers[k].onlyForRoot);
    return availableKeys.reduce((acc, key) => ({ ...acc, [key]: headers[key] }), {});
  };

  static handleExcelRow = (key, item) => {
    switch (key) {
      case RESERVES_EXCEL_HEADERS.created_at.key:
        return moment(item.created_at).format(DATE_FORMAT);
      case RESERVES_EXCEL_HEADERS.reservation_at.key:
        return moment(item.reservation_at).format(DATE_FORMAT);
      case RESERVES_EXCEL_HEADERS.phoneNumber.key:
        return convertPhoneNumber(item.client.phone);
      case RESERVES_EXCEL_HEADERS.hall.key:
        return Object.keys(item.objects?.zones || {})[0];
      case RESERVES_EXCEL_HEADERS.tables.key:
        return Object.values(item.objects?.zones || {})[0]?.join(', ');
      case RESERVES_EXCEL_HEADERS.comments.key:
        return item.comments?.map((i) => i.name).join(', ');
      case RESERVES_EXCEL_HEADERS.guestTags.key:
        return item.client.labels?.map((i) => i.name).join(', ');
      case RESERVES_EXCEL_HEADERS.reserveTags.key:
        return item.labels?.map((i) => i.name).join(', ');
      default: {
        return '';
      }
    }
  };

  static generateExcel = async (isRoot, filters, addressName) => {
    const data = await sendRequest({
      request: Api.getReserves,
      payload: {
        filters: {
          fields: {
            reservation_at: [DateServices.convertDateToLocal(filters.startDate), DateServices.convertDateToLocal(filters.endDate)],
          },
        },
        items_on_page: null,
      },
      warnErrorText: 'while getting all reserves',
    });

    if (data?.status !== 'ok') return;

    ExcelServices.generateFile({
      headers: this.filterExcelHeaders(RESERVES_EXCEL_HEADERS, isRoot),
      data: data.data.reservations,
      fn: this.handleExcelRow,
      fileName: this.generateExcelFileName(isRoot, filters, addressName),
    });
  };

  static generateExcelFileName = (isRoot, filters, addressName) => {
    const start = isRoot ? 'Резервы' : addressName.replaceAll(' ', '_');
    const end = `${moment(filters.startDate).format('DD_MM_YY')}_${moment(filters.endDate).format('DD_MM_YY')}`;
    return `${start}_${end}`;
  };

  static convertTableFilters = (filters, mode = 'table') => (
    mode === 'table' ? {
      items_on_page: this.RESERVES_PER_PAGE,
      page: filters.page,
      filters: {
        fields: {
          address_id: filters.addresses?.length ? filters.addresses : undefined,
          company_id: filters.partners?.length ? filters.partners : undefined,
          status_id: filters.statuses?.length ? filters.statuses : undefined,
          source_id: filters.sources?.length ? filters.sources : undefined,
          persons: !filters.countFrom && !filters.countTo ? undefined : [+filters.countFrom, +filters.countTo],
          summ: !filters.sumFrom && !filters.sumTo ? undefined : [+filters.sumFrom, +filters.sumTo],
          reservation_at: [DateServices.convertDateToLocal(filters.startDate), DateServices.convertDateToLocal(filters.endDate)],
        },
        string: filters.searchText,
      },
      sort: filters.sortKey ? `${filters.sortKey}.${filters.sortType}` : undefined,
    } : {
      items_on_page: null,
      filters: {
        fields: {
          reservation_at: [DateServices.convertDateToLocal(filters.startDate), DateServices.convertDateToLocal(filters.endDate)],
        },
      },
      sort: undefined,
    });

  static convertTableLabels = (reserve) => {
    const obj = {
      guestComment: reserve.comments?.length ? reserve.comments : [],
      reserveTag: reserve.labels || [],
      guestTag: reserve.client?.labels || [],
    };

    return Object
      .keys(obj)
      .filter((key) => obj[key].length)
      .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
  };

  static isAnyDropdownFilter = (filters, isRoot) => (filters.addresses?.length && isRoot)
        || (filters.partners?.length && isRoot)
        || filters.statuses?.length
        || filters.sources?.length
        || filters.countFrom
        || filters.countTo
        || filters.sumFrom
        || filters.sumTo;
}

export default ReservesServices;
