import React, { useMemo } from 'react';
import moment from 'moment';
import { RESERVES_CHARTS } from '../constants/charts';

const UseReservesCharts = (data) => {
  const firstData = useMemo(() => {
    const obj = {};
    data.forEach((item) => {
      const key = moment(item.reservation_at).format('DD.MM');
      if (obj[key]) {
        obj[key].push(item);
      } else {
        obj[key] = [item];
      }
    });

    const d1 = [];
    const d2 = [];

    Object.keys(obj).forEach((key) => {
      d1.push({
        primary: key,
        secondary: obj[key].length,
      });
      d2.push({
        primary: key,
        secondary: obj[key].reduce((a, i) => a + i.persons, 0),
      });
    });

    return [
      {
        label: 'Резервы',
        data: d1,
      },
      {
        label: 'Гости',
        data: d2,
      },
    ];
  }, [data]);

  const secondData = useMemo(() => {
    const obj = {};
    data.forEach((item) => {
      const key = item.status.name;
      if (obj[key]) {
        obj[key].push(item);
      } else {
        obj[key] = [item];
      }
    });

    return new Array(RESERVES_CHARTS.points.second.length).fill('').map((_, i) => ({
      title: RESERVES_CHARTS.points.second[i].name,
      value: obj[RESERVES_CHARTS.points.second[i].name]?.length || 0,
      color: RESERVES_CHARTS.points.second[i].color,
    }));
  }, [data]);

  const thirdData = useMemo(() => {
    const obj = {};
    data.forEach((item) => {
      const key = moment(item.reservation_at).format('HH:mm');
      if (obj[key]) {
        obj[key].push(item);
      } else {
        obj[key] = [item];
      }
    });

    const d1 = [];
    const d2 = [];

    Object.keys(obj).forEach((key) => {
      d1.push({
        primary: key,
        secondary: obj[key].length,
      });
      d2.push({
        primary: key,
        secondary: obj[key].reduce((a, i) => a + i.persons, 0),
      });
    });

    return [
      {
        label: 'Резервы',
        data: d1,
      },
      {
        label: 'Гости',
        data: d2,
      },
    ];
  }, [data]);

  const fourthData = useMemo(() => {
    const obj = {};
    data.forEach((item) => {
      const key = item.source;
      if (obj[key]) {
        obj[key].push(item);
      } else {
        obj[key] = [item];
      }
    });

    const d1 = [];
    const d2 = [];

    Object.keys(obj).forEach((key) => {
      d1.push({
        primary: key,
        secondary: obj[key].length,
      });
      d2.push({
        primary: key,
        secondary: obj[key].filter((i) => i.status.tag === 'came').length,
      });
    });

    return [
      {
        label: 'Резервы',
        data: d1,
      },
      {
        label: 'Пришли',
        data: d2,
      },
    ];
  }, [data]);

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: 'band',
      maxBandSize: 40,
      innerSeriesBandPadding: 0,
    }),
    [],
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        min: 0,
      },
    ],
    [],
  );

  const primaryAxisHorizontal = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: 'band',
      maxBandSize: 20,
      innerSeriesBandPadding: 0,
      position: 'left',
    }),
    [],
  );

  const secondaryAxesHorizontal = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        min: 0,
        position: 'bottom',
      },
    ],
    [],
  );

  const getDatumStyle = React.useCallback(
    () => ({
      rectangle: {
        rx: 4,
        ry: 4,
      },
    }),
    [],
  );

  return {
    firstData,
    secondData,
    thirdData,
    fourthData,
    primaryAxis,
    secondaryAxes,
    getDatumStyle,
    primaryAxisHorizontal,
    secondaryAxesHorizontal,
  };
};

export default UseReservesCharts;
