import React from 'react';
import { useAtomValue, useAtom } from 'jotai';
import styles from './css/index.module.scss';
import {
  OBJECT_FIELDS_ANYTHING, OBJECT_FIELDS_MAIN, OBJECT_FIELDS_SECONDARY, TOOlS_BUTTONS,
} from '../../constants';
import { objectsListAtom, selectedObjectAtom, selectedObjectIndexAtom } from '../../../../atoms/schemeAtoms';
import IconButton from '../../../../components/IconButton';
import MyInput from '../../../../components/MyInput';
import objectsFieldsSvgs from '../../../../helpers/objectsFieldsSvgs';
import PlanServices from '../../../../services/PlanServices';

function PlanObjectsFields() {
  const [objectsList, setObjectsList] = useAtom(objectsListAtom);
  const selectedObject = useAtomValue(selectedObjectAtom);
  const [selectedObjectIndex, setSelectedObjectIndex] = useAtom(selectedObjectIndexAtom);

  const handleFields = (val, field) => {
    const newObjectsList = [...objectsList];
    newObjectsList[selectedObjectIndex].fields[field] = val;
    setObjectsList(newObjectsList);
    setSelectedObjectIndex(selectedObjectIndex);
  };

  const onDeleteClick = () => {
    const newObjectsList = [...objectsList];
    newObjectsList.splice(selectedObjectIndex, 1);
    setObjectsList(newObjectsList);
    setSelectedObjectIndex(null);
  };

  const onCopyClick = () => {
    const x = selectedObject.fields.x + PlanServices.objectSteps.x;
    const y = selectedObject.fields.y + PlanServices.objectSteps.y;

    const selectedObjectWithoutServerId = { ...selectedObject };
    delete selectedObjectWithoutServerId.serverId;

    const newObject = PlanServices.generateNewObject(selectedObjectWithoutServerId, x, y, objectsList);

    setObjectsList((prev) => [...prev, newObject]);
    setSelectedObjectIndex(-1);
  };

  return (
    <div className={styles.wrapper}>
      {selectedObject.fieldsNames.includes(OBJECT_FIELDS_SECONDARY.title) && (
        <div className={styles.titleRow}>
          <MyInput
            value={selectedObject.fields.title || ''}
            onChange={(ev) => handleFields(ev.target.value, OBJECT_FIELDS_SECONDARY.title)}
            placeholder="Название"
            withLeftIcon={false}
          />
        </div>
      )}
      {selectedObject.fieldsNames.includes(OBJECT_FIELDS_SECONDARY.minCap) && (
        <div className={styles.capacityRow}>
          <MyInput
            value={selectedObject.fields.minCap || ''}
            onChange={(ev) => handleFields(ev.target.value, OBJECT_FIELDS_SECONDARY.minCap)}
            placeholder="Мин"
            withLeftIcon={false}
            type="number"
          />
          <MyInput
            value={selectedObject.fields.maxCap || ''}
            onChange={(ev) => {
              if (selectedObject.fields.minCap?.length === 0) handleFields('1', OBJECT_FIELDS_SECONDARY.minCap);
              handleFields(ev.target.value, OBJECT_FIELDS_SECONDARY.maxCap);
            }}
            placeholder="Макс"
            withLeftIcon={false}
            type="number"
          />
        </div>
      )}
      <div className={styles.buttonsList}>
        {selectedObject.fieldsNames.includes(OBJECT_FIELDS_MAIN.width) && (
          <>
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.widthPlus}
              onClick={() => handleFields(selectedObject.fields.width + PlanServices.objectSteps.width, OBJECT_FIELDS_MAIN.width)}
            />
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.widthMinus}
              onClick={() => handleFields(Math.max(selectedObject.fields.width - PlanServices.objectSteps.width, PlanServices.objectSteps.width), OBJECT_FIELDS_MAIN.width)}
            />
          </>
        )}
        {selectedObject.fieldsNames.includes(OBJECT_FIELDS_MAIN.height) && (
          <>
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.heightPlus}
              onClick={() => handleFields(selectedObject.fields.height + PlanServices.objectSteps.height, OBJECT_FIELDS_MAIN.height)}
            />
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.heightMinus}
              onClick={() => handleFields(Math.max(selectedObject.fields.height - PlanServices.objectSteps.height, PlanServices.objectSteps.width), OBJECT_FIELDS_MAIN.height)}
            />
          </>
        )}
        {selectedObject.fieldsNames.includes(OBJECT_FIELDS_MAIN.rotation) && (
          <>
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.rotateObjectRight}
              onClick={() => handleFields(selectedObject.fields.rotation + PlanServices.objectSteps.rotation, OBJECT_FIELDS_MAIN.rotation)}
            />
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.rotateObjectLeft}
              onClick={() => handleFields(selectedObject.fields.rotation - PlanServices.objectSteps.rotation, OBJECT_FIELDS_MAIN.rotation)}
            />
          </>
        )}
        {selectedObject.fieldsNames.includes(OBJECT_FIELDS_MAIN.borderRadius) && (
          <>
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.makeSquare}
              onClick={() => handleFields(PlanServices.objectSizes.borderRadiusSquare, OBJECT_FIELDS_MAIN.borderRadius)}
            />
            <IconButton
              wrapperClassName={styles.iconButton}
              svgPath={objectsFieldsSvgs.makeRound}
              onClick={() => handleFields(PlanServices.objectSizes.borderRadiusRound, OBJECT_FIELDS_MAIN.borderRadius)}
            />
          </>
        )}
        {selectedObject.fieldsNames.includes(OBJECT_FIELDS_ANYTHING.shapeCount) && (
          <IconButton
            wrapperClassName={styles.iconButton}
            svgPath={objectsFieldsSvgs.changeShape}
            onClick={() => handleFields(selectedObject.fields.shapeCount + 1 <= 8 ? selectedObject.fields.shapeCount + 1 : 3, OBJECT_FIELDS_ANYTHING.shapeCount)}
          />
        )}
        <IconButton
          wrapperClassName={styles.iconButton}
          svgPath={objectsFieldsSvgs.trash}
          onClick={onDeleteClick}
        />
        <IconButton
          wrapperClassName={styles.iconButton}
          svgPath={objectsFieldsSvgs.copy}
          onClick={onCopyClick}
        />
      </div>
    </div>
  );
}

export default PlanObjectsFields;
