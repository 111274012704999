export const RESERVES_EXCEL_HEADERS = {
  id: {
    key: 'id',
    path: 'id',
    headerText: 'ID',
    onlyForRoot: true,
  },
  company: {
    key: 'company',
    path: 'company.name',
    headerText: 'Партнер',
    onlyForRoot: true,
  },
  address: {
    key: 'address',
    path: 'address.name',
    headerText: 'Адрес',
    onlyForRoot: true,
  },

  created_at: {
    key: 'created_at',
    path: '',
    headerText: 'Дата создания',
  },
  reservation_at: {
    key: 'reservation_at',
    path: '',
    headerText: 'Дата резерва',
  },
  status: {
    key: 'status',
    path: 'status.name',
    headerText: 'Статус',
  },
  persons: {
    key: 'persons',
    path: 'persons',
    headerText: 'Кол-во',
  },
  phoneNumber: {
    key: 'phoneNumber',
    path: '',
    headerText: 'Телефон',
  },
  name: {
    key: 'name',
    path: 'client.name',
    headerText: 'Имя',
  },
  hall: {
    key: 'hall',
    path: '',
    headerText: 'Зал',
  },
  tables: {
    key: 'tables',
    path: '',
    headerText: 'Столы',
  },
  comments: {
    key: 'comments',
    path: '',
    headerText: 'Коммент',
  },
  guestTags: {
    key: 'guestTags',
    path: 'tags',
    headerText: 'Метки гостя',
  },
  reserveTags: {
    key: 'reserveTags',
    path: 'tags',
    headerText: 'Метки резерва',
  },
  source: {
    key: 'source',
    path: 'source',
    headerText: 'Источник',
  },
  sum: {
    key: 'sum',
    path: 'sum',
    headerText: 'Сумма',
  },
};

export const CONTACTS_EXCEL_HEADERS = {
  name: {
    key: 'name',
    path: 'name',
    headerText: 'Имя',
  },
  phoneNumber: {
    key: 'phoneNumber',
    path: '',
    headerText: 'Телефон',
  },
  guestTags: {
    key: 'guestTags',
    path: 'guestTags',
    headerText: 'Метки гостя',
  },
  reservations: {
    key: 'reservations',
    path: 'reservations',
    headerText: 'Резервы',
  },
  visits: {
    key: 'visits',
    path: 'visits',
    headerText: 'Пришёл',
  },
  missed: {
    key: 'missed',
    path: 'missed',
    headerText: 'Не пришёл',
  },
};

export const PARTNERS_EXCEL_HEADERS = {
  name: {
    key: 'name',
    path: 'name',
    headerText: 'Имя',
  },
  phoneNumber: {
    key: 'phoneNumber',
    path: '',
    headerText: 'Телефон',
  },
  guestTags: {
    key: 'guestTags',
    path: 'guestTags',
    headerText: 'Метки гостя',
  },
  reservations: {
    key: 'reservations',
    path: 'reservations',
    headerText: 'Резервы',
  },
  visits: {
    key: 'visits',
    path: 'visits',
    headerText: 'Пришёл',
  },
  missed: {
    key: 'missed',
    path: 'missed',
    headerText: 'Не пришёл',
  },
};
