import React from 'react';
import PageTitle from '../../components/PageTitle';

function Help() {
  return (
    <div className="help">
      <PageTitle text="Справка" />
    </div>
  );
}

export default Help;
