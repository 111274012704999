import React from 'react';
import classNames from 'classnames';
import styles from './css/index.module.scss';

export const TAGS_COLOR_MODES = {
  red: {
    textColor: '#EB5545',
    bgColor: '#FFE8E8',
  },
  yellow: {
    textColor: '#EE9F05',
    bgColor: '#FFF2E5',
  },
  violet: {
    textColor: '#250347',
    bgColor: '#EFE6FF',
  },
  green: {
    textColor: '#16E912',
    bgColor: '#ECFFEA',
  },
};

function ColorfulTag({
  text, colorMode, textColor = '#061F40', bgColor, className,
}) {
  if (colorMode) {
    textColor = colorMode.textColor;
    bgColor = colorMode.bgColor;
  }

  return (
    <p
      className={classNames(styles.text, className)}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {text}
    </p>
  );
}

export default ColorfulTag;
