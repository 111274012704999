import PapaParse from 'papaparse';
import moment from 'moment/moment';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import ExcelServices from './ExcelServices';
import { CONTACTS_EXCEL_HEADERS } from '../constants/excelHeaders';
import convertPhoneNumber from '../helpers/convertPhoneNumber';

class ContactsServices {
  static CONTACTS_PER_PAGE = 50;

  static handleExcelRow = (key, item) => {
    switch (key) {
      case CONTACTS_EXCEL_HEADERS.phoneNumber.key:
        return convertPhoneNumber(item.phone);
      case CONTACTS_EXCEL_HEADERS.guestTags.key:
        return item.labels?.map((i) => i.name).join(', ');
      default: {
        return '';
      }
    }
  };

  static generateExcel = async (filters, addressName) => {
    const data = await sendRequest({
      request: Api.getContacts,
      payload: { items_on_page: null, address_id: filters.address },
      warnErrorText: 'while getting all contacts',
    });

    if (data?.status !== 'ok') return;

    ExcelServices.generateFile({
      headers: CONTACTS_EXCEL_HEADERS,
      data: data.data.clients,
      fn: this.handleExcelRow,
      fileName: this.generateExcelFileName(filters, addressName),
    });
  };

  static generateExcelFileName = (filters, addressName) => {
    const start = addressName.replaceAll(' ', '_');
    const end = 'Контакты';
    return `${start}_${end}`;
  };

  static convertTableFilters = (filters) => ({
    items_on_page: this.CONTACTS_PER_PAGE,
    page: filters.page,
    address_id: filters.address,
    filters: {
      string: filters.searchText,
    },
    sort: filters.sortKey ? `${filters.sortKey}.${filters.sortType}` : undefined,
  });

  static convertImportedCSV = async (file) => {
    const promise = new Promise((res, rej) => {
      PapaParse.parse(file, {
        header: false,
        complete: (results) => {
          res({ status: 'ok', data: results.data });
        },
        error: (error) => {
          rej(error);
        },
      });
    });

    try {
      const data = await promise;
      if (data?.status !== 'ok') return;

      return data.data
        .filter((i) => i[0]?.length && i[1]?.length)
        .map((i) => ({
          name: i[0],
          phone: i[1],
          labels: i[2]?.split(', '),
        }));
    } catch (e) {
      console.warn(e);
    }
  };

  static checkImportedData = (data) => {
    let error = false;

    if (!data?.length) {
      error = true;
    }

    const convertedData = data.map((i) => (
      typeof i.name === 'string'
      && typeof i.phone === 'string' ? ({
          name: i.name,
          phone: this.convertPhoneToRaw(i.phone),
          labels: Array.isArray(i.labels) ? i.labels : [],
        }) : undefined)).filter((i) => i);

    return { error, data: convertedData };
  };

  static convertPhoneToRaw = (phone) => {
    const onlyNumbers = phone.replace(/\D/g, '').split('');
    if (onlyNumbers[0] === '8') {
      onlyNumbers[0] = '7';
    } else if (onlyNumbers[0] !== '7') { // not 7 not 8
      onlyNumbers.unshift('7');
    }

    return onlyNumbers.join('');
  };
}

export default ContactsServices;
