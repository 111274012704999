import React from 'react';
import './css/index.scss';
import classNames from 'classnames';
import useComponentVisible from '../../hooks/UseComponentVisible';

function ListItem({
  item,
  RightClickComponent,
  setSelected,
  selected,
  displayPath,
  keyPath,
  functionAtRightClick,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false, true);

  const onRightClick = (ev) => {
    ev.preventDefault();
    setIsComponentVisible(true);
    functionAtRightClick(item);
  };

  return (
    <div
      ref={ref}
      key={item[keyPath]}
      onContextMenu={onRightClick}
      className={`horizontal_list_item ${selected?.id === item.id ? 'selected' : ''} ${isComponentVisible && 'has_popup'}`}
    >
      <div
        className="horizontal_list_item_pressable_part"
        onClick={() => setSelected(item)}
      >
        <pre>
          {item[displayPath]}
        </pre>

      </div>
      {RightClickComponent && (
        <div
          onClick={() => setIsComponentVisible(false)}
          className={`horizontal_list_item_popup ${isComponentVisible && 'horizontal_list_item_popup_visible'}`}
        >
          {RightClickComponent}
        </div>
      )}
    </div>
  );
}

function MyHorizontalSelect({
  list,
  setSelected,
  selected,
  displayPath = 'name',
  keyPath = 'id',
  className,
  RightClickComponent,
  functionAtRightClick,
}) {
  return (
    list?.length > 0 && (
      <div className={classNames('horizontal_list', className)}>
        {list.map((item) => (
          <ListItem
            key={item[keyPath]}
            item={item}
            RightClickComponent={RightClickComponent}
            setSelected={setSelected}
            selected={selected}
            displayPath={displayPath}
            keyPath={keyPath}
            functionAtRightClick={functionAtRightClick}
          />
        ))}
      </div>
    )
  );
}

export default MyHorizontalSelect;
