import { atom } from 'jotai';
import { SidebarData } from '../helpers/SidebarData';

const addressesAtom = atom([]);
const selectedAddressAtom = atom(null);

const modulesAtom = atom([], (_, set, val) => {
  const res = [];
  val.sort((a, b) => (a.sort > b.sort ? 1 : -1)).forEach((item) => {
    const thisSidebarItem = SidebarData[item.module_name] || {};
    thisSidebarItem.title = item.name;
    thisSidebarItem.path = thisSidebarItem.path || item.module_name;
    thisSidebarItem.component = thisSidebarItem.component || item.module_name;
    thisSidebarItem.show = item.show ?? true;
    res.push(thisSidebarItem);
  });

  set(modulesAtom, res);
});

export {
  addressesAtom,
  selectedAddressAtom,
  modulesAtom,
};
