import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './css/index.module.scss';
import ArrowUpIcon from '../../assets/icons/arrowUp.svg';

function Accordion({
  text,
  component,
  isOpenInit = false,
  wrapperClassName,
  dynamicHeight,
  initComponentHeight = 0,
}) {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(isOpenInit);
  const [height, setHeight] = useState(initComponentHeight);

  useEffect(() => {
    if (!dynamicHeight) return;
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setHeight(entries[0].contentRect.height);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <div className={styles.trigger} onClick={() => setIsOpen((prev) => !prev)}>
        <p className={styles.triggerText}>{text}</p>
        <div className={classNames(styles.icon, { [styles.iconOpen]: isOpen })}>
          <img alt="^" src={ArrowUpIcon} />
        </div>
      </div>
      <div
        className={styles.accordion}
        style={{ maxHeight: `${isOpen ? height : 0}px` }}
      >
        <div className={styles.accordionInner} ref={containerRef}>
          {component}
        </div>
      </div>

    </div>
  );
}

export default Accordion;
