import React from 'react';
import { useAtomValue } from 'jotai/index';
import styles from './css/index.module.scss';
import MyButton from '../../../../components/MyButton';
import { deletingHallLoadingAtom } from '../../../../atoms/hallsAtoms';

function DeleteHallModal({ onDelete, onClose }) {
  const deletingHallLoading = useAtomValue(deletingHallLoadingAtom);

  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">Удалить?</p>
        <p className="modal_descr">
          Вы уверены что хотите удалить это помещение? Отменить данное действие будет
          невозможно.
        </p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text="Отменить" secondary onClick={onClose} disabled={deletingHallLoading} />
        <MyButton text="Удалить" onClick={onDelete} loading={deletingHallLoading} />
      </div>
    </div>
  );
}

export default DeleteHallModal;
