import widthPlus from '../assets/toolButtons/widthPlus.svg';
import widthMinus from '../assets/toolButtons/widthMinus.svg';
import heightPlus from '../assets/toolButtons/heightPlus.svg';
import heightMinus from '../assets/toolButtons/heightMinus.svg';
import rotateObjectRight from '../assets/toolButtons/rotateObjectRight.svg';
import rotateObjectLeft from '../assets/toolButtons/rotateObjectLeft.svg';
import makeSquare from '../assets/toolButtons/makeSquare.svg';
import makeRound from '../assets/toolButtons/makeRound.svg';
import trash from '../assets/toolButtons/trash.svg';
import copy from '../assets/toolButtons/copy.svg';
import changeShape from '../assets/toolButtons/changeShape.svg';

const objectsFieldsSvgs = {
  widthPlus,
  widthMinus,
  heightPlus,
  heightMinus,
  rotateObjectRight,
  rotateObjectLeft,
  makeSquare,
  makeRound,
  trash,
  copy,
  changeShape,
};

export default objectsFieldsSvgs;
