import React from 'react';
import PageTitle from "../../components/PageTitle";
import './css/index.scss';
import MyButton from "../../components/MyButton";
import FileIcon from "../../assets/formIcons/file.svg";

function Integration(props) {
    return (
        <div className="integration">
            <PageTitle text="Интеграция формы онлайн бронирования" />
            <div className="integration_content">
                <div className="integration_content_text">
                    Интеграция формы онлайн бронирования
                </div>
                <div className="integration_content_text_gray">
                    Изменять атрибуты кнопки можно стандартными методами CSS
                </div>
                <MyButton text="Забронировать стол" className="integration_content_button" />
                <div className="integration_content_text_icon">
                    <img src={FileIcon} alt="File"  />
                    Html-код для встраивания кнопки и формы онлайн бронирования на ваш сайт
                </div>
            </div>
        </div>
    );
}

export default Integration;
