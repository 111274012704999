import React, { useEffect, useRef } from 'react';
import { useAtom } from 'jotai/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from '../css/index.module.scss';
import PageTitle from '../../../components/PageTitle';
import MyButton from '../../../components/MyButton';
import MyInput from '../../../components/MyInput';
import {
  closeModalAtom, modalStateAtom, openInfoModalAtom, openLoadingModalAtom,
} from '../../../atoms/globalAtoms';
import { partnersFiltersAtom, partnersInfoAtom } from '../../../atoms/partnersAtoms';
import PartnersServices from '../../../services/PartnersServices';

function PartnersHeader() {
  const navigate = useNavigate();
  const [partnersInfo] = useAtom(partnersInfoAtom);
  const [partnersFilters, setPartnersFilters] = useAtom(partnersFiltersAtom);
  const [, openLoadingModal] = useAtom(openLoadingModalAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const onNewPartnerClick = () => {
    navigate('?selected_partner_id=-1');
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.headerLeftTop}>
          <PageTitle text="Партнеры" />
        </div>
        <div className={styles.headerLeftBottom}>
          <MyInput
            value={partnersFilters.searchText}
            onChange={(ev) => setPartnersFilters({ searchText: ev.target.value || undefined })}
            placeholder="Поиск"
            functionAtCleaning={() => setPartnersFilters({
              searchText: undefined,
              newDate: partnersFilters.searchText?.length ? '' : partnersFilters.date,
            })}
          />
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.headerRightTop}>
          <MyButton
            text="Новый партнер"
            onClick={onNewPartnerClick}
            className={styles.mainButton}
          />
        </div>
        <div className={styles.headerRightBottom}>
          <div className={styles.headerCount}>
            <span className={styles.headerCountLight}>Всего партнеров:</span>
            <span className={styles.headerCountBold}>{partnersInfo.pages?.total_records || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersHeader;
