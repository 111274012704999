import moment from 'moment/moment';
import deepClone from '../helpers/deepClone';

class DateServices {
  static getThisMonthFirstDay = () => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  static getThisMonthLastDay = () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setHours(23, 59, 59, 999);
    return lastDay;
  };

  static convertDateToLocal = (_date) => {
    const date = new Date(_date);
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };
}

export default DateServices;
