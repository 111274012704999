export const DEFAULT_WEEK_DAYS = [
  {
    weekday: 1,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
  {
    weekday: 2,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
  {
    weekday: 3,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
  {
    weekday: 4,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
  {
    weekday: 5,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
  {
    weekday: 6,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
  {
    weekday: 7,
    open_time: '11:30',
    close_time: '23:00',
    day_off: false,
  },
];

export const WEEK_DAYS_NAME = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
};
