import React from 'react';
import { useAtomValue } from 'jotai/index';
import styles from './css/index.module.scss';
import MyButton from '../../../../components/MyButton';
import { savingHallLoadingAtom } from '../../../../atoms/hallsAtoms';

function ChangeHallModal({ onGo, onSave }) {
  const savingHallLoading = useAtomValue(savingHallLoadingAtom);

  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">Перейти без сохранения?</p>
        <p className="modal_descr">
          У вас есть несохранённые изменения. Вы уверены, что хотите закрыть редактирование этого плана без сохранения?
        </p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text="Перейти без сохранения" className={styles.longBtn} secondary onClick={onGo} disabled={savingHallLoading} />
        <MyButton text="Сохранить" onClick={onSave} loading={savingHallLoading} />
      </div>
    </div>
  );
}

export default ChangeHallModal;
