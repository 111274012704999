import React from 'react';
import styles from './css/index.module.scss';

function EmptyHeight({ height }) {
  return (
    <div style={{ height: `${height}px` }} />
  );
}

export default EmptyHeight;
