export const getPoints = ({ sides, width, addToR = 0 }) => {
  const cx = width / 2;
  const cy = width / 2;
  const r = width / 2 + addToR;

  return Array.from({ length: sides })
    .map((_, i) => {
      const angle = ((2 * Math.PI) / sides) * i; // Calculate the angle for each vertex
      const x = cx + r * Math.cos(angle); // x coordinate
      const y = cy + r * Math.sin(angle); // y coordinate
      return `${x},${y}`;
    })
    .join(' ');
};

// Calculate the angle of the first two points (bottom side)
export const angleOfBottomLine = ({ sides, width }) => {
  const cx = width / 2;
  const cy = width / 2;
  const r = width / 2;

  const angleBetweenPoints = Math.atan2(
    cy + r * Math.sin(((2 * Math.PI) / sides)) - (cy + r * Math.sin(0)), // y2 - y1
    cx + r * Math.cos(((2 * Math.PI) / sides)) - (cx + r * Math.cos(0)), // x2 - x1
  );
  return (angleBetweenPoints * 180) / Math.PI; // Convert to degrees
};
