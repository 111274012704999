import parsePhoneNumberFromString from 'libphonenumber-js';

function convertPhoneNumber(phoneNumber) {
  try {
    if (!phoneNumber || phoneNumber.includes('+')) {
      // already formatted or not existing
      return phoneNumber;
    }

    if (typeof phoneNumber === 'number') phoneNumber = String(phoneNumber);

    const phoneNumberFormatted = parsePhoneNumberFromString(phoneNumber, 'RU');

    return phoneNumberFormatted.formatInternational() || phoneNumber;
  } catch (e) {
    return phoneNumber;
  }
}

export default convertPhoneNumber;
