import React from 'react';
import styles from '../css/index.module.scss';

function InputsBlock({ title, children, width = 440 }) {
  return (
    <div className={styles.inputBlock} style={{ width: `${width}px` }}>
      <p className={styles.inputBlockTitle}>{title}</p>
      {children}
    </div>
  );
}

export default InputsBlock;
