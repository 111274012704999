import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import Draggable from 'react-draggable';
import { schemeDotsAtom, schemeMiddleDotAtom } from '../../../../atoms/schemeAtoms';

function SchemeMiddleDot() {
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);
  const [schemeMiddleDot, setSchemeMiddleDot] = useAtom(schemeMiddleDotAtom);

  useEffect(() => {
    const newSchemeDots = [...schemeDots];

    const avgX = (newSchemeDots.reduce((acc, i) => acc + i[0], 0)) / newSchemeDots.length;
    const avgY = (newSchemeDots.reduce((acc, i) => acc + i[1], 0)) / newSchemeDots.length;
    setSchemeMiddleDot([avgX, avgY]);
  }, [schemeDots]);

  return (
    <Draggable
      position={{ x: schemeMiddleDot[0], y: schemeMiddleDot[1] }}
    >
      <div />
      {/* <div
        style={{
          position: 'absolute',
          width: `${5}px`,
          height: `${5}px`,
          background: 'red',
        }}
      /> */}
    </Draggable>
  );
}

export default SchemeMiddleDot;
