import React from 'react';
import ColorfulTag from '../../../components/ColorfulTag';
import styles from '../css/index.module.scss';

function TagsInfoComponent() {
  return (
    <div className={styles.tagsInfoComponent}>
      <ColorfulTag text="Комментарий гостя" bgColor="#FFFFFF" />
      <ColorfulTag text="Метка резева" bgColor="#E4FEE4" />
      <ColorfulTag text="Метка гостя" bgColor="#FEFCE4" />
    </div>
  );
}

export default TagsInfoComponent;
