import React from 'react';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { closeModalAtom } from '../../atoms/globalAtoms';
import MyButton from '../MyButton';

function InfoModal({
  title, text, buttonText = 'Ок', onButtonClick, buttonLoading,
}) {
  const closeModal = useSetAtom(closeModalAtom);

  const onClick = (ev) => {
    if (typeof onButtonClick === 'function') {
      onButtonClick(ev);
    } else {
      closeModal();
    }
  };

  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">{title}</p>
        <p className="modal_descr">{text}</p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text={buttonText} onClick={onClick} loading={buttonLoading} />
      </div>
    </div>
  );
}

export default InfoModal;
