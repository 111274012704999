import React, { useMemo } from 'react';
import { useAtom } from 'jotai/index';
import MyHorizontalSelect from '../../../components/MyHorizontalSelect';
import { partnersPageStateAtom, selectedAddressIdAtom, selectedPartnerAddressAtom } from '../../../atoms/partnersAtoms';
import { PARTNERS_PAGE_STATES } from '../../../constants/statuses';

function SinglePartnerTabs() {
  const [partnersPageState, setPartnersPageState] = useAtom(partnersPageStateAtom);
  const [selectedPartnerAddress] = useAtom(selectedPartnerAddressAtom);
  const [selectedAddressId] = useAtom(selectedAddressIdAtom);

  const data = useMemo(() => (
    partnersPageState === PARTNERS_PAGE_STATES.singlePartnerSingleAddress ? [
      { id: PARTNERS_PAGE_STATES.singlePartnerInfo, name: 'Общая информация' },
      { id: PARTNERS_PAGE_STATES.singlePartnerAddresses, name: 'Адреса' },
      { id: PARTNERS_PAGE_STATES.singlePartnerSingleAddress, name: selectedAddressId === -1 ? 'Новый адрес' : selectedPartnerAddress?.name },
    ] : [
      { id: PARTNERS_PAGE_STATES.singlePartnerInfo, name: 'Общая информация' },
      { id: PARTNERS_PAGE_STATES.singlePartnerAddresses, name: 'Адреса' },
    ]), [partnersPageState, selectedPartnerAddress, selectedAddressId]);

  const onSelect = (val) => {
    setPartnersPageState(val.id);
  };

  return (
    <MyHorizontalSelect
      list={data}
      setSelected={onSelect}
      selected={{ id: partnersPageState }}
    />
  );
}

export default SinglePartnerTabs;
