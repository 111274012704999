import React from 'react';
import { angleOfBottomLine, getPoints } from '../../../../../helpers/anythingSvgFunctions';

function AnyObject({ object, isSelected }) {
  const style = {
    flex: '0 0 auto',
    transform: `rotateZ(${object.fields.rotation}deg)`,
  };

  return (
    <div style={style}>
      <svg
        width={object.fields.width}
        height={object.fields.width}
        viewBox={`0 0 ${object.fields.width} ${object.fields.width}`}
        overflow="visible"
      >
        {isSelected && (
          <polygon
            points={getPoints({ sides: object.fields.shapeCount, width: object.fields.width, addToR: 5 })}
            fill="#fff"
            stroke="#dad300"
            strokeWidth={2}
            transform={`rotate(${angleOfBottomLine({ sides: object.fields.shapeCount, width: object.fields.width })} ${object.fields.width / 2} ${object.fields.width / 2})`} // Rotate around the center

          />
        )}
        <polygon
          points={getPoints({ sides: object.fields.shapeCount, width: object.fields.width })}
          fill="#CED7F5"
          transform={`rotate(${angleOfBottomLine({ sides: object.fields.shapeCount, width: object.fields.width })} ${object.fields.width / 2} ${object.fields.width / 2})`} // Rotate around the center
        />
      </svg>
    </div>
  );
}

export default AnyObject;
