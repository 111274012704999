import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './css/index.module.scss';
import Dropdown from '../Dropdown';

function DropdownInput({
  placeholder, value, onChange, error, data, wrapperClassName, labelTextTop,
}) {
  const [closeDropdown, setCloseDropdown] = useState();

  const handleClick = (val) => () => {
    onChange(val, true);
    closeDropdown();
  };

  return (
    <div
      className={classNames(styles.wrapper, wrapperClassName)}
    >
      <Dropdown
        text={value?.label || placeholder}
        inputProps={{
          error,
          labelTextTop,
          value: value?.label || '',
        }}
        setSetter={setCloseDropdown}
        component={(
          <div className={styles.component}>
            {data.map((item) => (
              <div
                key={item.value}
                className={styles.option}
                onClick={handleClick(item)}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
        height="unset"
      />
    </div>
  );
}

export default DropdownInput;
