import React from 'react';
import styles from './css/index.module.scss';
import ContactsHeader from './components/ContactsHeader';
import ContactsTable from './components/ContactsTable';
import useContacts from '../../hooks/UseContacts';

function Contacts() {
  const { saveImportedContacts } = useContacts();
  return (
    <div className={styles.contacts}>
      <ContactsHeader saveImportedContacts={saveImportedContacts} />
      <ContactsTable />
    </div>
  );
}

export default Contacts;
