import React from 'react';
import { useAtom } from 'jotai/index';
import classNames from 'classnames';
import moment from 'moment';
import styles from '../css/index.module.scss';
import InputsBlock from './InputsBlock';
import MyInput from '../../../components/MyInput';
import EmptyHeight from '../../../components/EmptyHeight';
import DropdownInput from '../../../components/DropdownInput';
import CalendarIcon from '../../../assets/icons/calendar.svg';
import ClipboardIcon from '../../../assets/icons/clipboard.svg';
import AddIcon from '../../../assets/icons/addGray.svg';
import CloseIcon from '../../../assets/formIcons/close.svg';
import ClipboardCopiedIcon from '../../../assets/icons/clipboard_copied.svg';
import useSingleAddressInputs from '../../../hooks/UseSingleAddressInputs';
import IconButton from '../../../components/IconButton';
import { WEEK_DAYS_NAME } from '../../../constants/addresses';
import MyCheckbox from '../../../components/MyCheckbox';
import PartnersServices from '../../../services/PartnersServices';

function SingleAddressInfo({ saveAddress }) {
  const {
    values, setters, errors, events,
  } = useSingleAddressInputs({ saveAddress });

  return (
    <div className={styles.singlePartnerInfo}>
      <InputsBlock title="Основные данные">
        <DropdownInput
          wrapperClassName={styles.input}
          value={values.active}
          onChange={setters.active}
          error={errors.active}
          data={[{ value: 'active', label: 'Активен' }, { value: 'nonActive', label: 'Заблокирован' }]}
          labelTextTop="Статус"
          placeholder="Выберите статус"
        />
        <MyInput
          wrapperClassName={styles.input}
          value={values.name}
          onChange={setters.name}
          error={errors.name}
          labelTextTop="Название адреса"
          withLeftIcon={false}
        />
        <MyInput
          wrapperClassName={styles.input}
          value={values.address}
          onChange={setters.address}
          error={errors.address}
          labelTextTop="Строка адреса"
          withLeftIcon={false}
        />
        <MyInput
          wrapperClassName={styles.input}
          value={values.phone}
          onChange={setters.phone}
          error={errors.phone}
          labelTextTop="Номер телефона"
          withLeftIcon={false}
        />
        {!!values.hostessLogin && (
          <div className={styles.hostessLogin}>
            <span>Логин хостеса</span>
            <div className={styles.hostessLoginRow}>
              <p>{values.hostessLogin}</p>
              <IconButton
                svgPath={values.hostessLoginCopied ? ClipboardCopiedIcon : ClipboardIcon}
                wrapperClassName={styles.hostessLoginButton}
                onClick={events.onClipboardClick}
              />
            </div>
          </div>
        )}
      </InputsBlock>
      <InputsBlock title="График работы" width={392}>
        {values.weekDays.map((item, index) => (
          <div className={styles.weekDay} key={item.weekday}>
            <p className={styles.weekDayName}>{WEEK_DAYS_NAME[item.weekday]}</p>
            <div className={styles.inputsRow}>
              <div className={styles.inputsRowLeft}>
                <div className={styles.weekDayInput}>
                  <MyInput
                    value={item.open_time}
                    onChange={setters.weekDays(index, 'open_time')}
                    className={classNames({ [styles.weekDayInputDisabled]: item.day_off })}
                    wrapperClassName={styles.weekDayInputWrapper}
                    withLeftIcon={false}
                    withCloseIcon={false}
                    disabled={item.day_off}
                    mask="99:99"
                    placeholder="11:30"
                  />
                </div>
                <div className={styles.weekDayInput}>
                  <MyInput
                    value={item.close_time}
                    onChange={setters.weekDays(index, 'close_time')}
                    className={classNames({ [styles.weekDayInputDisabled]: item.day_off })}
                    wrapperClassName={styles.weekDayInputWrapper}
                    withLeftIcon={false}
                    withCloseIcon={false}
                    disabled={item.day_off}
                    mask="99:99"
                    placeholder="23:00"
                  />
                </div>
              </div>

              <MyCheckbox
                value={item.day_off}
                text="Выходной"
                onClick={setters.weekDays(index, 'day_off')}
              />
            </div>
          </div>
        ))}

        <p className={styles.weekDayError}>{errors.weekDays}</p>
      </InputsBlock>

      <InputsBlock title="Выходные дни" width={660}>
        <div className={styles.holiday}>
          <div className={styles.inputsRow}>
            <div className={styles.inputsRowLeft}>
              <div className={styles.holidayDateInput}>
                <MyInput
                  value={values.holidayInputs.date}
                  onChange={setters.holidayInputs('date')}
                  withLeftIcon={false}
                  withCloseIcon={false}
                  mask="99.99.9999"
                  placeholder="01.01.2000"
                  floatingComponent={<img className={styles.dateInputIcon} src={CalendarIcon} alt="" />}
                />
              </div>
              <div className={styles.weekDayInput}>
                <MyInput
                  value={values.holidayInputs.open_time}
                  onChange={setters.holidayInputs('open_time')}
                  className={classNames({ [styles.weekDayInputDisabled]: values.holidayInputs.day_off })}
                  wrapperClassName={styles.weekDayInputWrapper}
                  withLeftIcon={false}
                  withCloseIcon={false}
                  disabled={values.holidayInputs.day_off}
                  mask="99:99"
                  placeholder="11:30"
                />
              </div>
              <div className={styles.weekDayInput}>
                <MyInput
                  value={values.holidayInputs.close_time}
                  onChange={setters.holidayInputs('close_time')}
                  className={classNames({ [styles.weekDayInputDisabled]: values.holidayInputs.day_off })}
                  wrapperClassName={styles.weekDayInputWrapper}
                  withLeftIcon={false}
                  withCloseIcon={false}
                  disabled={values.holidayInputs.day_off}
                  mask="99:99"
                  placeholder="23:00"
                />
              </div>
              <p className={styles.inputError}>{errors.holidayInputs}</p>
            </div>

            <MyCheckbox
              value={values.holidayInputs.day_off}
              text="Выходной"
              onClick={setters.holidayInputs('day_off')}
            />

            <IconButton onClick={events.onAddHolidayClick} svgPath={AddIcon} />
          </div>

          {values.holidays.map((item, index) => (
            <div className={styles.holidayItem} key={item.date}>
              <p className={styles.holidayItemDate}>{PartnersServices.convertDateFormat(item.date, 'DD.MM.YYYY', 'D MMMM')}</p>
              <p className={styles.holidayItemTime}>
                {item.day_off ? 'Выходной' : `${item.open_time}-${item.close_time}`}
              </p>
              <IconButton
                onClick={events.onDeleteHolidayClick(index)}
                svgPath={CloseIcon}
                wrapperClassName={styles.holidayItemButton}
              />
            </div>
          ))}
        </div>
      </InputsBlock>
    </div>
  );
}

export default SingleAddressInfo;
