import React from 'react';
import MyButton from '../../../components/MyButton';

function LogoutModal({ onLogout, onClose }) {
  return (
    <div className="modal_wrapper">
      <div className="modal_content">
        <p className="modal_title">Выйти?</p>
        <p className="modal_descr">
          Вы уверены что хотите выйти из аккаунта?
        </p>
      </div>
      <div className="modal_buttons_row">
        <MyButton text="Отменить" secondary onClick={onClose} />
        <MyButton text="Выйти" onClick={onLogout} />
      </div>
    </div>
  );
}

export default LogoutModal;
