import React from 'react';
import { useAtom } from 'jotai/index';
import moment from 'moment/moment';
import MyTable from '../../../components/MyTable';
import { RESERVES_HEADERS } from '../../../constants/tables';
import { reservesInfoAtom, reservesTableFiltersAtom } from '../../../atoms/reservesAtoms';
import styles from '../css/index.module.scss';
import EmptyHeight from '../../../components/EmptyHeight';
import ColorfulTag from '../../../components/ColorfulTag';
import { RESERVE_STATUSES } from '../../../constants/statuses';
import SmallCross from '../../../assets/icons/smallCross.svg';
import convertHeadersThatAreSortable from '../../../helpers/convertHeadersThatAreSortable';
import { closeModalAtom, modalStateAtom } from '../../../atoms/globalAtoms';
import CancelReserveModal from './CancelReserveModal';
import ReservesServices from '../../../services/ReservesServices';
import convertPhoneNumber from '../../../helpers/convertPhoneNumber';

function TableRow({ item, header, onCancelClick }) {
  switch (header.key) {
    case RESERVES_HEADERS.id.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.id}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.company.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.company?.name}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.address.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.address?.name}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.created_at.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {moment(item.created_at).format('DD.MM.YYYY')}
          </p>
          <EmptyHeight height={4} />
          <p className={styles.tableTextGray}>
            {moment(item.created_at).format('HH:mm')}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.reservation_at.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {moment(item.reservation_at).format('DD.MM.YYYY')}
          </p>
          <EmptyHeight height={4} />
          <p className={styles.tableTextGray}>
            {moment(item.reservation_at).format('HH:mm')}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.status.key: {
      return (
        <div className={styles.tableCell}>
          <div style={{ width: '200px' }}>
            <ColorfulTag
              text={item.status.name}
              colorMode={RESERVE_STATUSES[item.status.tag]?.colorMode}
            />
            {item.can_cancel && (
            <>
              <EmptyHeight height={8} />
              <div className={styles.iconButton} onClick={() => onCancelClick(item)}>
                <img src={SmallCross} alt="x" />
                <p className={styles.tableTextGray}>Отменить</p>
              </div>
            </>
            )}
          </div>
        </div>
      );
    }
    case RESERVES_HEADERS.client.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.client.name}
          </p>
          <EmptyHeight height={16} />
          <p className={styles.tableTextGray}>
            {convertPhoneNumber(item.client.phone)}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.persons.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.persons}
          </p>
        </div>
      );
    }
    case RESERVES_HEADERS.tables.key: {
      return (
        <div className={styles.tableCell}>
          {Object.keys(item.objects?.zones || {}).map((key, i) => (
            <div key={i} className={styles.tableObjects}>
              <p className={styles.tableTextGray}>{key}</p>
              <EmptyHeight height={16} />
              <div className={styles.tableObjectsTexts}>
                {item.objects.zones[key].map((obj, i2) => (
                  <p className={styles.tableText} key={i2}>{obj}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }
    case RESERVES_HEADERS.sum.key: {
      return (
        <div className={styles.tableCell} />
      );
    }
    case RESERVES_HEADERS.tags.key: {
      return (
        <div className={styles.tableCell}>
          {Object.keys(ReservesServices.convertTableLabels(item)).map((key) => (
            <div key={key} className={styles.tableLabels}>
              {ReservesServices.convertTableLabels(item)[key].map((label) => (
                key === 'guestComment' ? <p key={label.id} className={styles.tableText}>{label.name}</p>
                  : key === 'reserveTag' ? <ColorfulTag key={label.id} bgColor="#E4FEE4" text={label.name} />
                    : key === 'guestTag' ? <ColorfulTag key={label.id} bgColor="#FEFCE4" text={label.name} /> : null
              ))}
            </div>
          ))}
        </div>
      );
    }
    case RESERVES_HEADERS.source.key: {
      return (
        <div className={styles.tableCell}>
          <p className={styles.tableText}>
            {item.source}
          </p>
        </div>
      );
    }
    default: {
      return null;
    }
  }
}

function ReservesTable({ cancelReserve }) {
  const [reservesInfo] = useAtom(reservesInfoAtom);
  const [, setModalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const onCancelClick = (item) => {
    setModalState({
      isOpen: true,
      children: <CancelReserveModal
        onClose={closeModal}
        onConfirm={() => cancelReserve(item.id)}
      />,
    });
  };

  return (
    <MyTable
      data={reservesInfo.reservations}
      headers={convertHeadersThatAreSortable(RESERVES_HEADERS, reservesInfo.sort_fields)}
      filtersAtom={reservesTableFiltersAtom}
      renderComponent={(val) => <TableRow {...val} onCancelClick={onCancelClick} />}
      totalPages={Math.ceil((reservesInfo.pages?.total_records || 1) / ReservesServices.RESERVES_PER_PAGE)}
      heightToMinus={196}
    />
  );
}

export default ReservesTable;
