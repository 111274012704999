import React, { useEffect, useState } from 'react';

const UseResize = (ref) => {
  const [obj, setObj] = useState({
    width: 0, height: 0,
  });

  useEffect(() => {
    const getDimensions = (docObject) => (docObject ? ({
      width: docObject.offsetWidth,
      height: docObject.offsetHeight,
    }) : { width: 0, height: 0 });

    const handleResize = () => {
      setObj(getDimensions(ref?.current || document.body));
    };

    setObj(getDimensions(ref?.current || document.body));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return obj;
};

export default UseResize;
