import tableSvg from '../../../assets/toolButtons/table.svg';
import roundTableSvg from '../../../assets/toolButtons/roundTable.svg';
import wallSvg from '../../../assets/toolButtons/wall.svg';
import anythingSvg from '../../../assets/toolButtons/anything.svg';
import barChairSvg from '../../../assets/toolButtons/barChair.svg';
import filterArray from '../../../helpers/filterArray';
import PlanServices from '../../../services/PlanServices';

export const OBJECT_FIELDS_MAIN = {
  x: 'x',
  y: 'y',
  width: 'width',
  height: 'height',
  rotation: 'rotation',
  borderRadius: 'borderRadius',
};

export const OBJECT_FIELDS_SECONDARY = {
  title: 'title',
  minCap: 'minCap',
  maxCap: 'maxCap',
};

export const OBJECT_FIELDS_ANYTHING = {
  shapeCount: 'shapeCount',
};

export const OBJECT_TYPES = {
  table: {
    type: 'table',
    fieldsNames: [...Object.values(OBJECT_FIELDS_MAIN), ...Object.values(OBJECT_FIELDS_SECONDARY)],
    defaultFields: {
      [OBJECT_FIELDS_MAIN.width]: PlanServices.objectSizes.main,
      [OBJECT_FIELDS_MAIN.height]: PlanServices.objectSizes.main,
      [OBJECT_FIELDS_MAIN.rotation]: 0,
      [OBJECT_FIELDS_MAIN.borderRadius]: PlanServices.objectSizes.borderRadiusSquare,
      [OBJECT_FIELDS_SECONDARY.minCap]: '1',
      [OBJECT_FIELDS_SECONDARY.maxCap]: '4',
    },
  },
  roundTable: {
    type: 'table',
    fieldsNames: [...Object.values(OBJECT_FIELDS_MAIN), ...Object.values(OBJECT_FIELDS_SECONDARY)],
    defaultFields: {
      [OBJECT_FIELDS_MAIN.width]: PlanServices.objectSizes.main,
      [OBJECT_FIELDS_MAIN.height]: PlanServices.objectSizes.main,
      [OBJECT_FIELDS_MAIN.rotation]: 0,
      [OBJECT_FIELDS_MAIN.borderRadius]: PlanServices.objectSizes.borderRadiusRound,
      [OBJECT_FIELDS_SECONDARY.minCap]: '1',
      [OBJECT_FIELDS_SECONDARY.maxCap]: '4',
    },
  },
  wall: {
    type: 'wall',
    fieldsNames: [...filterArray(Object.values(OBJECT_FIELDS_MAIN), [OBJECT_FIELDS_MAIN.borderRadius])],
    defaultFields: {
      [OBJECT_FIELDS_MAIN.width]: PlanServices.objectSizes.short,
      [OBJECT_FIELDS_MAIN.height]: PlanServices.objectSizes.long,
      [OBJECT_FIELDS_MAIN.rotation]: 0,
      [OBJECT_FIELDS_MAIN.borderRadius]: 0,
    },
  },
  anything: {
    type: 'anything',
    fieldsNames: [...filterArray(Object.values(OBJECT_FIELDS_MAIN), [OBJECT_FIELDS_MAIN.borderRadius, OBJECT_FIELDS_MAIN.height]), OBJECT_FIELDS_SECONDARY.title, OBJECT_FIELDS_ANYTHING.shapeCount],
    defaultFields: {
      [OBJECT_FIELDS_MAIN.width]: PlanServices.objectSizes.main,
      [OBJECT_FIELDS_MAIN.height]: PlanServices.objectSizes.main,
      [OBJECT_FIELDS_MAIN.rotation]: 0,
      [OBJECT_FIELDS_MAIN.borderRadius]: 0,
      [OBJECT_FIELDS_ANYTHING.shapeCount]: 4,
    },
  },
  chair: {
    type: 'chair',
    fieldsNames: [...filterArray(Object.values(OBJECT_FIELDS_MAIN), [OBJECT_FIELDS_MAIN.borderRadius, OBJECT_FIELDS_MAIN.rotation, OBJECT_FIELDS_MAIN.height]), ...Object.values(OBJECT_FIELDS_SECONDARY)],
    defaultFields: {
      [OBJECT_FIELDS_MAIN.width]: PlanServices.objectSizes.middle,
      [OBJECT_FIELDS_MAIN.height]: PlanServices.objectSizes.middle,
      [OBJECT_FIELDS_MAIN.rotation]: 0,
      [OBJECT_FIELDS_MAIN.borderRadius]: PlanServices.objectSizes.borderRadiusRound,
      [OBJECT_FIELDS_SECONDARY.minCap]: '1',
      [OBJECT_FIELDS_SECONDARY.maxCap]: '1',
    },
  },
};

export const TOOlS_BUTTONS = [
  { svg: tableSvg, text: 'Стол', object: OBJECT_TYPES.table },
  { svg: roundTableSvg, text: 'Круглый стол', object: OBJECT_TYPES.roundTable },
  { svg: wallSvg, text: 'Перегородка', object: OBJECT_TYPES.wall },
  { svg: anythingSvg, text: 'Что угодно', object: OBJECT_TYPES.anything },
  { svg: barChairSvg, text: 'Барный стол', object: OBJECT_TYPES.chair },
  { },
];
