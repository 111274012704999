import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { v4 } from 'uuid';
import styles from './css/index.module.scss';
import PlanServices from '../../../../services/PlanServices';
import {
  gridRefCurrentAtom,
  gridZoomScaleAtom,
  schemeDotsAtom,
} from '../../../../atoms/schemeAtoms';

function Line({ coordinates1: [x1, y1], coordinates2: [x2, y2], onClick }) {
  const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

  return (
    <div
      style={{
        position: 'absolute',
        top: `${y1 + 6}px`,
        left: `${x1}px`,
        width: `${distance}px`,
        height: `${PlanServices.lineWidth}px`,
        backgroundColor: 'rgba(255,255,255,0)',
        // backgroundColor: 'rgba(255, 0, 0, 0.6)',
        transform: `rotate(${angle}deg)`,
        transformOrigin: '0 0',
        cursor: 'crosshair',
      }}
      onClick={onClick}
    />
  );
}

function SchemeLines() {
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);
  const gridZoomScale = useAtomValue(gridZoomScaleAtom);
  const gridRefCurrent = useAtomValue(gridRefCurrentAtom);

  const onClick = (leftDotIndex) => (ev) => {
    if (!gridRefCurrent) return;
    const rect = gridRefCurrent.getBoundingClientRect();

    const x = (ev.clientX - rect.left) / gridZoomScale;
    const y = (ev.clientY - rect.top) / gridZoomScale;

    const newDots = [...schemeDots];
    newDots.splice(
      leftDotIndex + 1,
      0,
      [x, y, v4()],
    );
    setSchemeDots(newDots);
  };

  return (
    schemeDots.map((dot, index) => {
      const nextIndex = index === schemeDots.length - 1 ? 0 : index + 1;
      const coordinates1 = schemeDots[index];
      const coordinates2 = schemeDots[nextIndex];

      return (
        <Line
          key={dot[2]}
          coordinates1={coordinates1}
          coordinates2={coordinates2}
          onClick={onClick(index)}
        />
      );
    })
  );
}

export default SchemeLines;
