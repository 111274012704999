import React, { useMemo } from 'react';
import './css/index.scss';
import LeftIcon from '../../assets/icons/arrowLeft.svg';
import RightIcon from '../../assets/icons/arrowRight.svg';

function getPagesArray(page, pagesCount = 1) {
  const count = Math.max(1, Math.ceil(pagesCount));
  return Array.from({ length: count }, (_, index) => index + 1);
}

function TablePagination({ page, setPage, pagesCount }) {
  const arr = useMemo(() => getPagesArray(page, pagesCount), [page, pagesCount]);

  const onLeftClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onRightClick = () => {
    if (page < pagesCount) {
      setPage(page + 1);
    }
  };

  return (
    <div className="pagination_wrapper">
      {arr.length > 1 && (
        <div className="pagination_button pagination_button_left" onClick={onLeftClick}>
          <img src={LeftIcon} alt="" />
        </div>
      )}

      <div className="pagination_page_row">
        {arr.map((pageItem) => (
          <div
            key={pageItem}
            className={`pagination_item ${pageItem === page ? 'selected' : ''}`}
            onClick={() => setPage(pageItem)}
          >
            {pageItem}
          </div>
        ))}
      </div>

      {arr.length > 1 && (
        <div className="pagination_button pagination_button_right" onClick={onRightClick}>
          <img src={RightIcon} alt="" />
        </div>
      )}
    </div>
  );
}

export default TablePagination;
