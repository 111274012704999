import React from 'react';
import styles from '../css/index.module.scss';

function ReservesChartWrapper({
  width,
  height,
  title,
  headerPadding,
  children,
  points,
}) {
  return (
    <div className={styles.chartsItem} style={{ width: `${width}px`, height: `${height}px` }}>
      <div className={styles.chartsItemHeader} style={{ paddingLeft: `${headerPadding}px` }}>
        <p className={styles.chartsItemTitle}>{title}</p>
        <div className={styles.chartsItemPoints}>
          {points.map((item) => (
            <div className={styles.chartsItemPoint} key={item.name}>
              <div className={styles.chartsItemPointColor} style={{ backgroundColor: item.color }} />
              <p className={styles.chartsItemPointText}>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.chartsItemChart}>
        {children}
      </div>
    </div>
  );
}

export default ReservesChartWrapper;
