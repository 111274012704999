import moment from 'moment';
import sendRequest from '../helpers/sendRequest';
import Api from '../api';
import ExcelServices from './ExcelServices';
import { PARTNERS_EXCEL_HEADERS } from '../constants/excelHeaders';
import convertPhoneNumber from '../helpers/convertPhoneNumber';
import { DATE_FORMAT } from '../constants';
import 'moment/locale/ru';

moment.locale('ru');

class PartnersServices {
  static PARTNERS_PER_PAGE = 50;

  static ADDRESSES_PER_PAGE = 50;

  static handleExcelRow = (key, item) => {
    switch (key) {
      case PARTNERS_EXCEL_HEADERS.phoneNumber.key:
        return convertPhoneNumber(item.phone);
      case PARTNERS_EXCEL_HEADERS.guestTags.key:
        return item.labels?.map((i) => i.name).join(', ');
      default: {
        return '';
      }
    }
  };

  static generateExcel = async (filters, addressName) => {
    const data = await sendRequest({
      request: Api.getPartners,
      payload: { items_on_page: null, address_id: filters.address },
      warnErrorText: 'while getting all partners',
    });

    if (data?.status !== 'ok') return;

    ExcelServices.generateFile({
      headers: PARTNERS_EXCEL_HEADERS,
      data: data.data.clients,
      fn: this.handleExcelRow,
      fileName: this.generateExcelFileName(filters, addressName),
    });
  };

  static generateExcelFileName = (filters, addressName) => {
    const start = addressName.replaceAll(' ', '_');
    const end = 'Контакты';
    return `${start}_${end}`;
  };

  static convertTableFilters = (filters) => ({
    items_on_page: this.PARTNERS_PER_PAGE,
    page: filters.page,
    address_id: filters.address,
    filters: {
      string: filters.searchText,
    },
    sort: filters.sortKey ? `${filters.sortKey}.${filters.sortType}` : undefined,
  });

  static convertAddressesTableFilters = (filters, partnerId) => ({
    id: partnerId,
    items_on_page: filters.items_on_page || this.PARTNERS_PER_PAGE,
    page: filters.page,
    filters: {
      string: filters.searchText,
    },
    sort: filters.sortKey ? `${filters.sortKey}.${filters.sortType}` : undefined,
  });

  static convertSavingPayload = (obj) => ({
    info: {
      id: obj.id || undefined,
      name: obj.name,
      descr: obj.descr,
      inn: obj.inn,
      kpp: obj.kpp,
      phone: obj.phone,
      email: obj.email,
      site: obj.site,
      info: obj.info,
      agreement_number: obj.number,
      agreement_date: moment(obj.date, 'DD.MM.YYYY').format(DATE_FORMAT),
      active: obj.active.value === 'active',
    },
    user: {
      login: obj.email,
      name: obj.fio,
      phone: obj.phone,
      password: obj.password,
    },
  });

  static convertAddressSavingPayload = (obj) => ({
    partnerId: obj.partnerId,
    id: obj.id || undefined,
    name: obj.name,
    descr: obj.address,
    phone: obj.phone,
    active: obj.active.value === 'active',
    schedule: [
      ...obj.weekDays.map((i) => ({
        weekday: i.weekday,
        open_time: this.convertFormatToSeconds(i.open_time),
        close_time: this.convertFormatToSeconds(i.close_time),
        day_off: i.day_off,
      })),

      ...obj.holidays.map((i) => ({
        date: this.convertDateFormat(i.date, 'DD.MM.YYYY', 'YYYY-MM-DD'),
        open_time: this.convertFormatToSeconds(i.open_time),
        close_time: this.convertFormatToSeconds(i.close_time),
        day_off: i.day_off,
      })),
    ],
  }
  );

  static convertFormatToSeconds = (format) => moment.duration(format).asSeconds();

  static convertSecondsToFormat = (seconds) => moment.utc(seconds * 1000).format('HH:mm');

  static convertDateFormat = (date, from, to) => moment(date, from).format(to);
}

export default PartnersServices;
