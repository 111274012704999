import React from 'react';
import './css/index.scss';
import Modal from 'react-modal';
import { useAtom, useSetAtom } from 'jotai';
import { closeModalAtom, modalStateAtom } from '../../atoms/globalAtoms';

function MyModal() {
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const {
    isOpen, closeFunction, children, style,
  } = modalState;

  const closeOnPressOutside = typeof modalState.closeOnPressOutside === 'boolean' ? modalState.closeOnPressOutside : true;

  const handleClosing = () => {
    if (typeof closeFunction === 'function') {
      closeFunction();
    }
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeOnPressOutside ? handleClosing : undefined}
      style={{ overlay: styles.overlay, content: { ...styles.content, ...style } }}
      closeTimeoutMS={200}
    >
      {children}
    </Modal>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(6,31,64,0.5)',
    zIndex: 10,
  },
  content: {
    position: 'absolute',
    height: 'fit-content',
    width: 'fit-content',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '20px',
    padding: '0',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default MyModal;
